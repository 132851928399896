'use strict';

const toggleBtn = document.querySelector('.js-toggleBtn');
const nav = document.querySelector('.c-nav');
const header = document.querySelector('.c-header');
const sideBar = document.querySelector('.c-sidebar');

toggleBtn.addEventListener('click', function () {
  toggleBtn.classList.toggle('is-active');
  nav.classList.toggle('is-active');
  if (nav.classList.contains('is-active')) {
    nav.style.height = `calc(100vh - ${window.getComputedStyle(header).height} - ${window.getComputedStyle(sideBar).height})`
    nav.style.top = `${window.getComputedStyle(header).height}`
    document.querySelector('body').style.overflow = `hidden`;
  } else {
    document.querySelector('body').style.overflow = `auto`;
  }
})

window.addEventListener('resize', function () {
  nav.style.height = `100%`;

})